import {size} from 'lodash';

import ResourceLink from 'components/links/Resource';
import InlineList from 'components/shared/InlineList';
import Fact from 'components/shared/fact';

export default function RelatedGenesFact({genes}) {
  let label = 'Associated Gene';
  if (size(genes) > 1) {
    label += 's';
  }
  
  let content;
  if (size(genes) > 1) {
    content = <InlineList collection={genes} />;
  } else if (size(genes) === 1) {
    content = <ResourceLink resource={genes[0]} />;
  }
  
  return (
    <Fact label={label}>{content}</Fact>
  );
}
