import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import RelatedGenesFact from 'pages/Allele/OverviewTab/RelatedGenesFact';

export default function AlleleOverviewTab({allele}) {
  return (
    <div className="overviewTab">
      <FactSection>
        <Fact label="Symbol">{allele.symbol}</Fact>
        <RelatedGenesFact genes={allele.relatedGenes} />
      </FactSection>

      <FactSection title="Definitions">
        {allele.definitions.map((def, i) => (
          <Fact key={def.id} label={def.assembly || 'Gene'}>{def.name}</Fact>
        ))}
      </FactSection>
    </div>
  );
}
